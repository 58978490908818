import React, {useState} from 'react';
import FormGroup from "../elements/form-group";
import FormGroupFile from "../elements/form-group-file";
import FormGroupTextarea from "../elements/form-group-textarea";
import Arrow from "../elements/arrow";
import ReCAPTCHA from "react-google-recaptcha";

import {renderRichText} from "gatsby-source-contentful/rich-text";

function JobsSingle(props) {


    const siteKey = "6LctEOcUAAAAADbusysUKozmWClBgY_1z7lzeviQ";
    const actionURL = 'https://getform.io/f/83c7bc00-33cd-49be-811e-0389c9399c3d'

    const [expired, setExpired] = useState(false)
    const [callback, setCallback] = useState(false)
    const [token, setToken] = useState(false)

    const reCaptchaRef = React.createRef();

    const handleChange = value => {
        setToken(value);
        // if value is null recaptcha expired
        if (value === null) setExpired(true);
    };

    const asyncScriptOnLoad = () => {
        setCallback('called');
    };

    const handleSubmit = (e) =>{
        e.preventDefault();
        const token = reCaptchaRef.current.getValue();

        if(token){
            document.getElementById('job-form').submit();
        }
    }

    const {job} = props;
    return (
        <>
            <section className="jobs-single-section pb-[30px] md:pb-[120px]">
                <div className={'container'}>
                    <div className='job-title'>
                        <h2 className={'title'}>
                            {job.title}
                        </h2>
                        <div className="city mt-1.5 md:mt-[15px]">
                            {job.location}
                        </div>
                    </div>
                    <div className="grid jobs-single overflow-hidden grid-cols-1 md:grid-cols-2 md:gap-x-[90px]">

                        <div className="order-1 col-span-2 md:col-span-1">
                            <div className='job-content mt-[30px] md:mt-20'>
                                {renderRichText(job.body)}
                            </div>
                        </div>

                        <div className="job-form order-3 col-span-2 md:order-2 md:col-span-1 mt-10 md:mt-[80px] ">
                            <div className='bg-[#EFF3F4] p-[30px] md:p-[50px] rounded-xl'>
                                <h2 className={'h2 form-title'}>
                                    Apply for job
                                </h2>
                                <form onSubmit={handleSubmit} id={'job-form'} action={actionURL} encType="multipart/form-data"  className='mt-[30px]' method={'post'} >
                                    <input type="hidden" id={'job'} name={'job'} value={`${job.title} - ${job.location}`}/>
                                    <div className={'grid grid-cols-1 xl:grid-cols-2 gap-x-4'}>
                                        <FormGroup id={'name'} label={'Fullname'} type={'text'} bg={`white`} class={'col-span-2 xl:col-span-1'}  required={true}/>
                                        <FormGroup id={'email'} label={'Email'} type={'email'} bg={`white`} class={'col-span-2 xl:col-span-1'} required={true}/>
                                        <FormGroupFile name={'file-1'} label={'Upload CV'} type={'file'} class={'col-span-2'} bg={`white`} required={true}/>
                                        <FormGroupFile name={'file-2'} label={'Cover Letter'} type={'file'} class={'col-span-2'} bg={`white`} required={true}/>
                                        <FormGroupTextarea id={'message'} label={'Message'} class={'col-span-2'} bg={`white`} required={true}/>
                                    </div>
                                    <div className={'mb-12'}>
                                        <ReCAPTCHA
                                            sitekey={siteKey}
                                            ref={reCaptchaRef}
                                            onChange={handleChange}
                                            asyncScriptOnLoad={asyncScriptOnLoad}
                                        />
                                    </div>
                                    <button disabled={token === false} className={'simple-button bg-yellow'}>
                                        <div className={'flex items-center'}>
                                            <div className={'basis-auto'}>
                                                Send Application
                                            </div>
                                            <div className={'basis-auto pl-4'}>
                                                <Arrow/>
                                            </div>
                                        </div>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JobsSingle;
